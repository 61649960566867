import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./Update.scss";
import "../NewsInfo/NewsInfo.scss";
import Bookcard from "../BookCard/BookCard";
import HTMLReactParser from "html-react-parser";
import VipFormPage from '../forms/vip-member-form';
// Header component

const Update = (props) => {
    const bookcardData = [
        {
            bookcardTitle: 'Sell or Let your property with us',
            bookcardContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitam accumsan.',
            bookcardBtn: 'book an appointment',
            bookcardLink: '#'
        }
    ]
    return (
        <React.Fragment>
            <section className="updated-info news-info-section">
                <Container>
                    <Row>
                        <Col md={12}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="content-box">
                                    {props?.data?.Content_with_Image.map((each, i) =>
                                        <React.Fragment key={i}>
                                            {each.Show_Form_On_top &&
                                                <div className='vip-contactform'>
                                                    <ScrollAnimation animateIn="fadeInUp">
                                                        <div className="contact-section">
                                                            <div className='contact-wrapper'>
                                                                <VipFormPage
                                                                    // options={props?.modules?.filter(c => c.Options).map(c => c.Options.split("\n")).flat()}
                                                                    formname={'How to Sign Up'}
                                                                // email={props?.modules?.filter(c => c.Select_Member != null)[0]?.Select_Member?.Email}
                                                                />
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            }
                                            {each.Content && 

                                                <Col md={12}>
                                                    <div className="news-details content-wrap">
                                                        {HTMLReactParser(each.Content)}
                                                    </div>
                                                </Col>
                                            }
                                            <Col md={12} className="news-img-wrapper">
                                                {each.Images.map((img, k) =>
                                                    <div className="news-info-img " >
                                                        <img src={img?.url} alt={img?.alternativeText ? img?.alternativeText : props?.data?.Title + " - tlc Estate Agents"} />
                                                    </div>
                                                )}
                                            </Col>
                                            {each.Show_Book_Cta_On_Bottom &&
                                                <Col md={12} className="book-news-card">
                                                    <Bookcard
                                                        bookcardTitle={bookcardData[0].bookcardTitle}
                                                        bookcardContent={bookcardData[0].bookcardContent}
                                                        bookcardBtn={bookcardData[0].bookcardBtn}
                                                        bookcardLink={bookcardData[0].bookcardLink}
                                                    />
                                                </Col>
                                            }
                                        </React.Fragment>
                                    )}

                                </div>
                            </ScrollAnimation>


                        </Col>
                    </Row>
                </Container>
            </section>

        </React.Fragment>
    )
}
export default Update
