import React, { useState, useEffect} from "react";
import '../NewsEvents/NewsEvents.scss';
import NewsCard from '../NewsCard/NewsCard';
import Slider from "react-slick";
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from 'react-bootstrap';
import { PageLinks } from '../common/constant';
import "./LocalVips.scss";
import classNames from 'classnames';

const LocalVips = (props) => {
    const [selectedVideo, setSelectedVideo] = useState("");
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                vipLists {
                    _id
                    Title
                    Category : VIP_Category {
                        Title
                    }
                    Tile_Image {
                        alternativeText
                        url
                    }
                    Video_Url
                    Url
                    Sub_Category
                    imagetransforms
                }
            }
        }
    `)
    var settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                }
            }
        ]
    };

    return (
        <div className="event-section local-news">
            <Container>
                <div className='event-slider'>
                    <span className={classNames('sub-title', { "text-start": props.title != undefined })}>{props.title ? props.title : "local news"}</span>
                    <Slider className="slick-arrow" {...settings}>
                        {data?.glstrapi?.vipLists.map((each, index) => (
                            <NewsCard
                                key={each?._id}
                                id={each?._id}
                                //  imagename={`vip.Tile_Image.details_${index % 3}`}
                                imagename={`vip-list.Tile_Image.details_1`}
                                selectedVideo={selectedVideo}
                                setSelectedVideo={(val) => setSelectedVideo(val)}
                                newsCard={{
                                    newsTitle: each?.Title,
                                    newsTag: each?.Sub_Category,
                                    newsImg: each?.Tile_Image?.url,
                                    newsImgAlt: each?.Tile_Image.url?.alternativeText,
                                    newsImgTransforms: each?.imagetransforms,
                                    newsUrl: each?.Url + "/",
                                    id: each?._id
                                }}
                                video={each?.Video_Url}
                                path={`/${PageLinks?.tlc_vip}/`}
                            />
                        ))}
                    </Slider>
                </div>
            </Container>
        </div>
    );

}

export default LocalVips;
